// window.$ = window.jQuery = require('jquery');

// import 'alpinejs';

import ajaxLoadMore from './ajaxLoadMore.js'
import reCaptcha from './recaptcha.js'
// import gallery from './gallery.js'


ajaxLoadMore()
reCaptcha();
// gallery()