// import axios from 'axios';

const ajaxLoadMore = () => {

  const button = document.querySelector('.load-more');

  if (typeof (button) != 'undefined' && button != null) {

    button.addEventListener('click', (e) => {
      e.preventDefault();

      let current_page = document.querySelector('.posts-list').dataset.page;
      let max_pages = document.querySelector('.posts-list').dataset.max;

      let params = new URLSearchParams();
      params.append('action', 'load_more_posts');
      params.append('current_page', current_page);
      params.append('max_pages', max_pages);

      axios.post('/wp-admin/admin-ajax.php', params)
        .then(res => {

          let posts_list = document.querySelector('.posts-list');

          posts_list.innerHTML += res.data.data;

          document.querySelector('.posts-list').dataset.page++;

          if (document.querySelector('.posts-list').dataset.page == 3) {
            button.parentNode.removeChild(button);

            // const alink = document.querySelector('load-all');

            // $('<a class="text-blue-700 border border-blue-700 bg-transparent hover:bg-blue-800 hover:border-blue-800 hover:text-white font-medium py-2 px-4 border rounded transition duration-500 ease-in-out" href="example.com/script.php?id="></a>');
          }

        })

    });

  }

}

export default ajaxLoadMore;
