
const reCaptcha = () => {

  const pnzForm = document.getElementById('pnz');

  if (pnzForm) {
    pnzForm.addEventListener('submit', (e) => {
      e.preventDefault();
      var rcres = grecaptcha.getResponse();
      if(rcres.length){
        grecaptcha.reset();
        jQuery('svg').removeClass('hidden');
        let data =  new FormData(pnzForm);
        axios.post('/wp-admin/admin-ajax.php', data)
        .then(res => {
          jQuery('svg').addClass('hidden');
          alert(res.data.data)
          window.location = '/pnz';
        })
        .catch(function (error) {
         alert(error)
        });
      }else{
        alert( "Prosimo potrdite, da niste robot" );
      }
        // });
    });
}

}

export default reCaptcha;